<style scoped>
	a.card-service {
        text-align: center;
        padding: 20px;
        border-radius: 15px;
        border: 2px solid #4a97f7;
        color: #000;
        /* background: #4a97f7;
        color: #fff; */
    }
    .box-option {
        background: #56ccf2; /* fallback for old browsers */
        background: -webkit-linear-gradient(to left, #56ccf2, #2f80ed); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to left, #56ccf2, #2f80ed); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        border-radius: 20px;
        color:#fff;
    }
    .box-option .image {
        /* float: left; */
        /* height: 70px;
        width: 70px;
        text-align: center; */
    }
    .box-option .info {
    	padding-bottom: 10px;
        /* padding: 20px 20px 25px 0;
        margin-left: 90px; */
        text-align: center;
    }
    .box-option .text {
        font-weight: bold;
    }
    a.card-service img {
        height: 40px;
    }
    a.card-service div.service-name {
        padding-top: 10px;
        font-weight: bold;
    }
	.box-service img {
		height: 45px;
	}
	.box-service p.text-service {
	    margin-top: 10px;
		font-size: 15px;
		font-weight: bold;
	}
	.box-service-panel {
		border: 2px solid #4a97f7;
		border-radius: 20px;
	}
	.box-service-panel:hover {
	    cursor: pointer;
		box-shadow: 0 0 0 1.5px #4a97f7;
	}
	.box-service-panel .panel-body a {
	    text-decoration: none;
		color: inherit;
	}
	.img-owner-service {
		height: 35px;
	}
	.service-title {
		font-size: 25px;
	}
	.profile-image {
        height: 60px;
        width: 100%;
    }
	.box-option, .statics {
        padding: 15px 15px 8px 15px !important;
    }
    .statics .count {
        text-align: center;
    }
    .statics .image {
        float: left;
        height: 70px;
        width: 70px;
        text-align: center;
    }
    .statics .info {
        padding: 7px 7px 7px 0;
        margin-left: 90px;
    }
    .statics .text {
        font-weight: bold;
    }
    .statics .number {
        font-weight: bold;
        color: #4a97f7;
        font-size: 17px;
        display: block;
        padding-top: 3px;
    }
    .service-count {

    }
    .service-count img {
        height: 30px;
        border-radius: 5px;
    }
    .count-service {
        padding-top: 3px;
        font-weight: bold;
    }
    .count-service-box {
    	text-align: center!important;
    	padding: 15px 0 10px 0;
    }
    .cn-notify {
        padding-top: 5px;
    }

    .list-notification {
        height: 250px;
        overflow: auto;
    }

    .list-notification::-webkit-scrollbar-track {
        border-radius: 15px;
    }

    .list-notification::-webkit-scrollbar {
        width: 5px;
    }

    .list-notification::-webkit-scrollbar-thumb {
        background-color: #6c757d;
        border-radius: 15px;
    }

    .noti-alerts {
        padding: 5px 12px 10px 12px;
    }

    .total-record {
        text-align: center;
        padding-top: 12px;
    }
    .total-record .counter {
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 18px;
    }
</style>
<template>
	<div v-if="is_logged">
		<div class="row">
            <div class="col-lg-4">
                <div class="panel">
                    <div class="panel-body statics">
                        <div class="image">
                            <inline-svg :src="require('@/assets/images/icons/coin.svg')" class="profile-image"></inline-svg>
                        </div>
                        <div class="info">
                            <span class="text">TỔNG TIỀN HIỆN TẠI</span>
                            <span class="number">
                                <ICountUp :delay="0" class="counter" :endVal="user.money || 0"></ICountUp> {{ settings.currency }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="panel">
                    <div class="panel-body statics">
                        <div class="image">
                            <inline-svg :src="require('@/assets/images/icons/coin_month.svg')" class="profile-image"></inline-svg>
                        </div>
                        <div class="info">
                            <span class="text">TỔNG NẠP THÁNG</span>
                            <span class="number">
                                <ICountUp :delay="0" class="counter" :endVal="user.money_deposited_month || 0"></ICountUp> {{ settings.currency }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="panel">
                    <div class="panel-body statics">
                        <div class="image">
                            <inline-svg :src="require('@/assets/images/icons/coin_all.svg')" class="profile-image"></inline-svg>
                        </div>
                        <div class="info">
                            <span class="text">TỔNG NẠP TẤT CẢ</span>
                            <span class="number">
                                <ICountUp :delay="0" class="counter" :endVal="user.money_deposited || 0"></ICountUp> {{ settings.currency }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="user.count_services">
            <div class="col-lg-7">
                <div class="panel">
                    <div class="panel-body">
                        <h3 class="panel-title">
                            <inline-svg :src="require('@/assets/images/icons/notification.svg')" class="panel-icon" alt="" /> Thông báo
                        </h3>
                        <div class="list-notification" v-if="notifyLists.length > 0">

                            <div class="alert noti-alerts text-pre-wrap" :class="noti.class" v-for="(noti, k) in notifyLists">
                                <sub class="float-left">{{ site.notificationType[noti.type] }} ( {{ noti.action_time | timeText }} )</sub>
                                <div v-html="noti.content" class="cn-notify"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="panel">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="total-record">
                                <div class="font-weight-bold">Tổng số lượng đã tăng</div>
                                <div class="au counter">
                                    <ICountUp :delay="0" :endVal="user.total_run || 0"></ICountUp> / {{ user.total_quantity | numberFormat }}
                                </div>
                                <div class="progress" style="margin:7px;">
                                    <div class="progress-bar progress-bar-striped active b-main" role="progressbar" :aria-valuenow="user.total_processbar" aria-valuemin="0" aria-valuemax="1000" :style="{'width': user.total_processbar + '%'}">{{ user.total_processbar || 0 }}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row count-service-box">
                        <div class="col-lg-3 col-xs-4" v-for="(count, provider) in user.count_services" style="padding-bottom:10px;">
                            <div class="service-count">
                                <img :src="require('@/assets/images/service/' + provider + '.png')" alt="">
                                <div class="count-service">
                                    <ICountUp :delay="0" class="counter" :endVal="count || 0"></ICountUp>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel">
                    <div class="panel-body box-option">
                        <div class="text-center">
                            <span class="text">LIÊN HỆ HỖ TRỢ</span>
                        </div>
                        <div class="text-pre-wrap info-support" v-if="settings.info_support" v-html="settings.info_support_html" style="padding:10px;"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="panel">
                    <router-link to="profile">
                        <div class="panel-body box-option">
                            <div class="image">
                                <inline-svg :src="require('@/assets/images/icons/profile.svg')" class="profile-image"></inline-svg>
                            </div>
                            <div class="info">
                                <span class="text">
                                	<i class="fa fa-angle-double-left"></i> 
                                		THÔNG TIN TÀI KHOẢN 
                                	<i class="fa fa-angle-double-right"></i>
                                </span>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="panel">
                    <router-link to="payment">
                        <div class="panel-body box-option">
                            <div class="image">
                                <inline-svg :src="require('@/assets/images/icons/payment.svg')" class="profile-image" alt=""></inline-svg>
                            </div>
                            <div class="info">
                                <span class="text">
                                	<i class="fa fa-angle-double-left"></i> 
                                		NẠP TIỀN TÀI KHOẢN 
                                	<i class="fa fa-angle-double-right"></i>
                                </span>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="panel">
                    <router-link to="history">
                        <div class="panel-body box-option">
                            <div class="image">
                                <inline-svg :src="require('@/assets/images/icons/history.svg')" class="profile-image" alt=""></inline-svg>
                            </div>
                            <div class="info">
                                <span class="text">
                                	<i class="fa fa-angle-double-left"></i> 
                                		LỊCH SỬ HOẠT ĐỘNG 
                                	<i class="fa fa-angle-double-right"></i>
                                </span>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="panel">
                    <router-link to="services/lists">
                        <div class="panel-body box-option">
                            <div class="image">
                                <inline-svg :src="require('@/assets/images/icons/list.svg')" class="profile-image" alt=""></inline-svg>
                            </div>
                            <div class="info">
                                <span class="text">
                                	<i class="fa fa-angle-double-left"></i> 
                                		TẤT CẢ TIẾN TRÌNH 
                                	<i class="fa fa-angle-double-right"></i>
                                </span>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

		<!-- <div class="row">
			<div class="col-lg-5">
				<div class="panel panel-default">
					<div class="panel-body">
						<h3 class="panel-title" style="margin-bottom: -20px;"><i class="fa fa-user"></i> Thông tin</h3>
						<div class="media">
						    <div class="media-left">
						        <img src="https://image.flaticon.com/icons/png/512/1177/1177568.png" class="media-object img-rounded" style="width:90px">
						    </div>
						    <div class="media-body">
						        <h4 class="media-heading f-bold">{{ user.username }}</h4>
						        <div class="b-noti b-success">
						        	<ICountUp :delay="0" class="counter" :endVal="user.money || 0"></ICountUp> {{ settings.currency }}
						        </div>
						        <div class="b-noti b-danger">{{ user.roleText }} - Giảm {{ user.discount }}%</div>
						    </div>
						    <router-link to="/profile" class="btn b-info btn-block"><i class="fa fa-user"></i> Thông tin & mật khẩu</router-link>
						    <router-link to="/payment" class="btn b-purple btn-block"><i class="fa fa-user"></i> Nạp tiền vào tài khoản</router-link>
						    <router-link to="/history" class="btn b-orange btn-block"><i class="fa fa-user"></i> Lịch sử hoạt động</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-7">
				<div class="panel panel-default" style="border:2px dashed #389af0;border-radius: 15px;">
					<div class="panel-body">
						<h3 class="panel-title text-center">Thông báo hệ thống</h3>
						<div class="text-center">
							<img src="/assets/images/graphic5.png" height="80" alt="">
						</div>
						<div style="padding-top:12px;"><?= text_style($settings->notification); ?></div>
					</div>
				</div>
			</div>
		</div> -->

		<div v-for="(customServices, k) in services">
			<h2 class="text-center service-title f-bold"><img :src="require('@/assets/images/service/' + customServices.icon + '.png')" class="img-owner-service" alt=""> {{ customServices.name }}</h2>
			<div class="row">
				<div class="col-lg-3 col-xs-6" v-for="(itemServices, ks, index) in customServices.items">
			        <div class="panel box-service-panel">
						<div class="panel-body text-center">
							<router-link :to="'/' + customServices.link.replace('{type}', ks)" title="">
							    <div class="box-service">
								    <img :src="require('@/assets/images/service/' + customServices.icon + '/' + ks + '.png')" height="50" alt=""><br>
							        <p class="text-service">{{ customServices.action }} {{ itemServices.name }}</p>
							    </div>
							</router-link>
						</div>
					</div>
			    </div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		props: ['user', 'site', 'services', 'settings', 'is_logged', 'notification'],
        computed: {
            notifyLists () {
                let d = [];
                Object.keys(this.notification).filter(k => {
                    if (k != 'popup') {
                        this.notification[k].filter(v => {
                            v['type'] = k;
                            d.push(v);
                        });
                    }
                });
                d.sort((a, b) => b.action_time - a.action_time);
                return d;
            }
        },
		created () {
			this.$store.dispatch('siteData').then((data) => {
                if (data.last_notify) {
                    this.$swal({
                        title: 'Nạp tiền thành công',
                        html: data.last_notify,
                        icon: 'success',
                        /*imageUrl: require('@/assets/images/icons/money.svg'),
                        imageHeight: '100px'*/
                    });
                }
            });
            $('body').removeClass('bg-main');
		}
	}
</script>