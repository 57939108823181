import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import numeral from 'numeral';

// vue-plugins
import VueResource from 'vue-resource';
import VueClipboard from 'vue-clipboard2';
import VueNumeric from 'vue-numeric';
import InlineSvg from 'vue-inline-svg';
import ICountUp from 'vue-countup-v2';
import FastClick from 'vue-fastclick';
import VueSweetAlert2 from 'vue-sweetalert2';

// component
import Notification from './views/Component/Notification';

// import css lib
import 'sweetalert2/dist/sweetalert2.min.css';

FastClick.attach(document.body);

Vue.config.productionTip = false;

Vue.use(VueResource);
Vue.use(VueClipboard);

Vue.component('VueNumeric', VueNumeric);
Vue.component('InlineSvg', InlineSvg);
Vue.component('ICountUp', ICountUp);
Vue.component('Notification', Notification);


Vue.use(VueSweetAlert2, {
    title: 'Thông báo',
    cancelButtonColor: 'rgb(224, 56, 56)',
    cancelButtonText: 'Hủy',
});


Vue.http.options.root = '/api/';
// Vue.http.options.root = 'https://ctv2.sieulike.me/api/';
Vue.http.options.emulateHTTP = true;
Vue.http.options.emulateJSON = true;

Vue.http.interceptors.push(function (request, next) {
    store.state.loader = true;
    next((response) => {
        store.state.loader = false;
        if (response.body) {
            if (response.body.encoding) {
                response.body = JSON.parse(atob(decodeURIComponent(response.body.data.substr(0, response.body.data.length - 45))));
            }
            if (response.body.go_to) {
                router.push(response.body.go_to).catch(err => {});
            }
            if (!!response.body.destroy_storage) {
                localStorage.clear();
            }
            if (!!response.body.is_update) {
                store.dispatch('siteData');
            }
        }
    });
    if (store.getters.is_logged) {
        request.headers.set('AuthToken', store.state.auth_token);
    }
});

Vue.filter('numberFormat', function (value) {
    return numeral(value).format('0,0');
});

Vue.filter('ucfirst', function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
});

Vue.filter('tableRows', function (rows) {
    return (rows == -1 ? 'Hiển thị tất cả' : 'Hiển thị ' + numeral(rows).format('0,0'));
});

Vue.filter('providerText', function (text) {
    let name = text.split('_').join(' ');
    name = name.charAt(0).toUpperCase() + name.slice(1);
    name = name.replace('buff', '');
    name = name.replace('vip', 'VIP');
    return name;
});

Vue.filter('timeText', function (value, $b = 'trước') {
    var $musty = Array('giây', 'phút', 'giờ', 'ngày', 'tuần', 'tháng', 'năm', 'thập kỷ');
    var $format = Array('60', '60', '24', '7', '4.35', '12', '10');
    var $textTime = (new Date / 1E3 | 0) - value;
    for (var $i = 0; $textTime >= $format[$i] && $i < $format.length - 1; $i++) {
        $textTime /= $format[$i];
    }
    return Math.round($textTime) + ' ' + $musty[$i] + ' ' + $b;
});

router.beforeEach((to, from, next) => {
    if (['Login', 'Register', 'SetupSite', 'CloseSite', '503'].includes(to.name)) {
        if (store.getters.is_logged) {
            next('/');
        }
    } else {
        if (!store.getters.is_logged) {
            next('/login');
        } else {

            if (to.name.includes('Admin') && store.state.user.role != 'admin') {
                next('/');
            }
        }
    }
    next();
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");