import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        auth_token: localStorage.getItem('auth_token') || null,
        servicesProvider: [
            'facebook_vip',
            'facebook_buff',
            'instagram_buff',
            'tiktok_buff',
            'shopee_buff'
        ],
        loader: false,
        limitRowsTable: [
            10, 50, 100, 500, 1000, 2000, -1
        ],
        site_name: window.location.hostname,
        base_url: window.location.protocol + '//' + window.location.hostname,
        user: {},
        payment: {},
        settings: {},
        services: {},
        site: {},
        notification: {},
        owner_data: {},
        updateSidebar: 0
    },
    getters: {
        is_logged(state) {
            return !!state.auth_token;
        }
    },
    mutations: {
        setAuthToken(state, data) {
            state.auth_token = data;
            localStorage.setItem('auth_token', data);
        },
        setServices(state, data) {
            state.services = data;
        },

    },
    actions: {
        userData({ state }) {
            Vue.http.get('user').then(response => {
                state.user = response.body.user;
            });
        },
        siteData({ commit, state }) {
            return new Promise((resolve, reject) => {
                Vue.http.post('site').then(response => {
                    state.settings = response.body.settings;
                    if (response.body.user) {
                        state.user = response.body.user;
                    }
                    if (response.body.site) {
                        state.site = response.body.site;
                    }
                    if (response.body.owner_data) {
                        state.owner_data = response.body.owner_data;
                    }
                    if (response.body.services) {
                        state.services = response.body.services;
                    }
                    if (response.body.notification) {
                        state.notification = response.body.notification;
                    }
                    if (response.body.payment) {
                        state.payment = response.body.payment.filter((x, i) => {
                            x.info = x.info.replace(/copy{(.*)}/g, function (match, str) {
                                return `<a class="au font-weight-bold cursor-pointer" data-copy="${str}">${str} <i class="fa fa-copy"></i></a>`;
                            });
                            return x;
                        });
                    }
                    resolve(response.body);
                }).catch(err => {
                    reject(err);
                });
            });
        },
        logout({ state }) {
            state.user = {};
            state.auth_token = null;
            state.updateSidebar = 0;
            localStorage.clear();
            Vue.http.post('logout');
        }
    },
    modules: {},
});