<style>
    svg {
        fill: #eef0f8;
    }
    .icon-size {
        height: 30px;
        width: 30px;
        margin-right: 3px;
        vertical-align: middle;
    }
    .size-drop {
        height: 25px;
        width: 25px;
    }
    .services-drop img {
        height: 25px;
    }
    .navbar-brand img {
        max-width:100px; 
        margin-top: -5px;
        height: 30px;
    }
</style>
<template>
    <div id="app" :class="{preloader: $store.state.loader}">

        <div class="_loader" v-if="$store.state.loader"></div>

        <div id="header_script"></div>

        <div v-if="!is_logged">
            <router-view :settings="settings" :site="site" />
        </div>
        
        <div v-else>

            <nav class="navbar navbar-default" role="navigation">
                <div class="container-fluid">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="navbar-header">
                        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <router-link class="navbar-brand hidden-lg" to="/">
                            <img :src="settings.site_logo" v-if="isURL(settings.site_logo)" alt="">
                            <span v-else>
                                {{ settings.site_logo }}
                            </span>
                        </router-link>
                    </div>
                    <div class="collapse navbar-collapse navbar-ex1-collapse">
                        <ul class="nav navbar-nav">


                            <li :class="{dropdown: links.item}" v-for="links in navbarLinks">

                                <router-link v-if="links.item" :to="links.path" class="dropdown-toggle" data-toggle="dropdown">
                                    <inline-svg :src="links.icon" class="icon-size" />
                                    {{ links.name }} <b class="caret"></b>
                                </router-link>

                                <router-link v-if="!links.item" :to="links.path" @click.native="clickNavbar">
                                    <inline-svg :src="links.icon" class="icon-size" />
                                    {{ links.name }}
                                </router-link>

                                <ul class="dropdown-menu" v-if="links.item">
                                    <li v-for="itemPath in links.item">
                                        <router-link :to="itemPath.path" @click.native="clickNavbar">{{ itemPath.name }}</router-link>
                                    </li>
                                </ul>
                            </li>


                            <li class="dropdown services-drop hidden-lg" v-for="(customServices, k) in services">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                                    <img :src="require('@/assets/images/service/' + customServices.icon + '.png')" alt="">
                                    Dịch vụ {{ customServices.name }} <b class="caret"></b>
                                </a>
                                <ul class="dropdown-menu">
                                    <li v-for="(itemServices, ks, index) in customServices.items">
                                        <router-link :to="'/' + customServices.link.replace('{type}', ks)" @click.native="clickNavbar">{{ customServices.action }} {{ itemServices.name }}</router-link>
                                    </li>
                                </ul>
                            </li>
                            
                        </ul>
                        <ul class="nav navbar-nav navbar-right">
                            <li class="dropdown">
                                <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                                    <img :src="require('@/assets/images/avatars/profile-image-2.png')" class="icon-size img-rounded" />
                                    {{ user.username }} - <ICountUp :delay="0" class="counter" :endVal="user.money || 0"></ICountUp> {{ settings.currency }}
                                    <b class="caret"></b>
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <router-link to="/profile" @click.native="clickNavbar">
                                            <inline-svg :src="require('@/assets/images/icons/profile.svg')" class="icon-size size-drop" /> Thông tin
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/payment" @click.native="clickNavbar">
                                            <inline-svg :src="require('@/assets/images/icons/payment.svg')" class="icon-size size-drop" /> 
                                            Nạp tiền
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/history" @click.native="clickNavbar">
                                            <inline-svg :src="require('@/assets/images/icons/history.svg')" class="icon-size size-drop" /> 
                                            Lịch sử
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/" @click.native="logout">
                                            <inline-svg :src="require('@/assets/images/icons/logout.svg')" class="icon-size size-drop" />  Đăng xuất
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div><!-- /.navbar-collapse -->
                </div>
            </nav>
            <div class="container">
                <router-view 
                    :user="user" 
                    :services="services" 
                    :payment="payment" 
                    :settings="settings" 
                    :notification="notification"
                    :site="site"
                    :navbarInfo="navbarInfo" 
                    :key="$route.fullPath"
                    :tabs="tabs"
                    :is_logged="is_logged"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import "@/assets/fonts/font-awesome/css/font-awesome.min.css";
    import "@/assets/libs/bootstrap/css/bootstrap.min.css";
    import "@/assets/css/custom.css";

    export default {
        data () {
            return {

            }
        },
        created () {

            document.title = localStorage.getItem('site_title');

            this.$store.dispatch('siteData').then((data) => {
                if (data.settings) {
                    localStorage.setItem('site_title', data.settings.site_title);

                    document.title = data.settings.site_title;
                    document.querySelector('[rel="shortcut icon"]').href = data.settings.site_icon;

                    if (data.notification) {
                        if (data.notification.popup) {
                            this.$swal({
                                html: data.notification.popup[0].content,
                                imageUrl: require('@/assets/images/icons/notification.svg'),
                                imageHeight: '100px',
                                confirmButtonText: 'Đã đọc'
                            });
                        }
                    }
                    if (this.$store.getters.is_logged) {
                        if (!data.user) {
                            this.logout();
                        }
                    }
                    this.runHeaderScript();
                }
                
            });

        },
        computed: {
            activePage: function (p) {
                return {
                    
                }
            },
            header_script () {
                return this.settings.header_script;
            },
            navbarInfo () {
                return this.navbarLinks.filter(x => x.path === this.$route.path)[0];
            },
            is_logged () {
                return this.$store.getters.is_logged;
            },
            user () {
                return this.$store.state.user || {};
            },
            notification () {
                return this.$store.state.notification;
            },
            services () {
                return this.$store.state.services;
            },
            payment () {
                return this.$store.state.payment;
            },
            settings () {
                return this.$store.state.settings || {};
            },
            site () {
                return this.$store.state.site;
            },
            navbarLinks () {
                var navbarLinks = [
                    {
                        name: 'Trang chủ',
                        path: '/',
                        icon: require('@/assets/images/icons/home.svg')
                    },
                    /*{
                        name: 'Tạo Website CTV',
                        path: '/site_ctv',
                        icon: require('@/assets/images/icons/api.svg')
                    },*/
                    {
                        name: 'Bảng giá',
                        path: '/price',
                        icon: require('@/assets/images/icons/price.svg')
                    },
                    {
                        name: 'Thông tin',
                        path: '/profile',
                        icon: require('@/assets/images/icons/profile.svg')
                    },
                    {
                        name: 'Nạp tiền',
                        path: '/payment',
                        icon: require('@/assets/images/icons/payment.svg')
                    },
                    /*{
                        name: 'Chuyển tiền',
                        path: 'transfers',
                        icon: require('@/assets/images/icons/transactions.svg'),
                        show: (this.settings.is_users_transfers == 1 ? true : false)
                    },*/
                    {
                        name: 'Lịch sử',
                        path: '/history',
                        icon: require('@/assets/images/icons/history.svg'),
                    },
                    {
                        name: 'Tiến trình',
                        path: '/services/lists',
                        icon: require('@/assets/images/icons/list.svg')
                    }
                ];
                if (this.user.role == 'admin') {
                    navbarLinks.push({
                        name: 'Admin Area',
                        path: '',
                        icon: require('@/assets/images/icons/admin.svg'),
                        item: [
                            {
                                name: 'Thành viên',
                                path: '/admin/users'
                            },
                            {
                                name: 'Cộng/Trừ tiền',
                                path: '/admin/transactions'
                            },
                            /*{
                                name: 'Thống kê doanh thu',
                                path: '/admin/statics'
                            },*/
                            {
                                name: 'Cài đặt thanh toán',
                                path: '/admin/payment'
                            },
                            {
                                name: 'Cài đặt thông báo',
                                path: '/admin/notification'
                            },
                            {
                                name: 'Cài đặt thẻ cào',
                                path: '/admin/card'
                            },
                            {
                                name: 'Cài đặt dịch vụ',
                                path: '/admin/services'
                            },
                            {
                                name: 'Cài đặt chung',
                                path: '/admin/settings'
                            }
                        ]
                    });
                }
                return navbarLinks;
            }
        },
        methods: {
            runHeaderScript () {
                let header_script = this.header_script;
                if (header_script) {
                    //jQuery(($) => {
                        $('#header_script').html(header_script);
                    //});
                }
            },
            clickNavbar () {
                if ($(window).width() <= 1054) {
                    $('button.navbar-toggle').click();
                }
            },
            isURL (url) {
                if (url) {
                    return url.match(/^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/);
                }
            },
            tabs (tab) {
                jQuery(($) => {
                    $('.nav-link[href="#'+ tab +'"]').tab('show');
                });
            },
            logout () {
                this.$store.dispatch('logout').then((res) => {
                    this.$router.push('/login').catch(err => err);
                });
            },
        }
    }
</script>