import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/setup',
        name: 'SetupSite',
        component: () => import('../views/Auth.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Auth.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Auth.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/Profile.vue')
    },
    {
        path: '/history',
        name: 'History',
        component: () => import('../views/History.vue')
    },
    {
        path: '/vip_:service',
        name: 'ServiceVIP',
        component: () => import('../views/Service/VIP/Buy.vue')
    },
    {
        path: '/buff_:service',
        name: 'ServiceBuff',
        component: () => import('../views/Service/Buff/Buy.vue')
    },
    {
        path: '/payment',
        name: 'Payment',
        component: () => import('../views/Payment.vue')
    },
    {
        path: '/price',
        name: 'Price',
        component: () => import('../views/Price.vue')
    },
    {
        path: '/admin/users',
        name: 'AdminUsers',
        component: () => import('../views/Admin/Users.vue')
    },
    {
        path: '/admin/transactions',
        name: 'AdminTransaction',
        component: () => import('../views/Admin/Transaction.vue')
    },
    {
        path: '/admin/statics',
        name: 'AdminStatics',
        component: () => import('../views/Admin/Statics.vue')
    },
    {
        path: '/admin/payment',
        name: 'AdminPayment',
        component: () => import('../views/Admin/Payment.vue')
    },
    {
        path: '/admin/services',
        name: 'AdminServices',
        component: () => import('../views/Admin/Services.vue')
    },
    {
        path: '/admin/card',
        name: 'AdminSettingsCard',
        component: () => import('../views/Admin/SettingsCard.vue')
    },
    {
        path: '/admin/notification',
        name: 'AdminNotification',
        component: () => import('../views/Admin/Notification.vue')
    },
    {
        path: '/admin/settings',
        name: 'AdminSettings',
        component: () => import('../views/Admin/Settings.vue')
    },
    {
        path: '/503',
        name: '503',
        component: () => import('../views/503.vue')
    },
    {
        path: '/close',
        name: 'CloseSite',
        component: () => import('../views/Close.vue')
    },
    {
        path: '/services/vip_:type',
        name: 'ServiceVIPList',
        component: () => import('../views/Service/VIP/List.vue')
    },
    {
        path: '/services/:type',
        name: 'ServiceList',
        component: () => import('../views/Service/Buff/List.vue')
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior () {
        jQuery('html, body').animate({
            scrollTop: 0
        }, 700, 'swing');
        // document.getElementById('app').scrollIntoView();
    }
})

export default router